import React, { useState }from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
export default function Forgotpassword()
{
	const [email, setEmail] = useState('');
  const navigate = useNavigate('');
  const [errorMessage, setErrorMessage] = useState('');
const ApiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = async() =>{
   await fetch(ApiUrl+'admin/forgot_password', {
    method: 'POST',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      
    
    },
    body: JSON.stringify({email})
  }).then(res => {return res.json()}).then(data => {
  console.log(data);
    if(data.data !== null)
    {
    //<Nav to="/dashboard" />
	setErrorMessage('password send on your email please check and login');
		navigate('/');
    }
    else
    {
      setErrorMessage('Email Not matched');
    }


  }).catch(err=>{
    console.log(err)
})
  //console.log(result.json())
  } 

    return <div>

<div className="card card-outline card-primary" style={{padding : "70px"}}>
    <div className="card-header text-center">
      <span className="h1"><b>Jemster Admin</b></span>
    </div>
    <div className="card-body">
      <p className="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
      <form  method="post">
        <div className="input-group mb-3">
          <input type="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button type="button" onClick={handleSubmit} className="btn btn-primary btn-block">Request new password</button>
          </div>
         {errorMessage && <div class="alert alert-danger" role="alert"> {errorMessage} </div>}
        </div>
      </form>
      <p className="mt-3 mb-1">
        <Link to="/">Login</Link>
      </p>
    </div>
   
  </div>

    </div>


}