import React from "react";

export default function Footer()
{

return  <aside><footer className="main-footer">
<strong>Copyright &copy; 2022 <a href="https://leadmanch.com">LeadManch.com</a>.</strong>
All rights reserved.
<div className="float-right d-none d-sm-inline-block">
  <b>Version</b> 3.2.0
</div>
</footer>
</aside>
}