import React from "react";




export default function Header(){

    return <header><nav className="main-header navbar navbar-expand navbar-dark">
   
    <ul className="navbar-nav">
      <li className="nav-item">
        <span className="nav-link" data-widget="pushmenu"  role="button"><i className="fas fa-bars"></i></span>
      </li>
      
    </ul>

   
    <ul className="navbar-nav ml-auto">
    
     
    </ul>
  </nav>
  </header>
}